.lets-learn-page {
    text-align: center;
    padding: 50px;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    font-family: 'Roboto', sans-serif;
    margin-top: 100px;
  }
  
  .header {
    background-color: #FFA500;
    color: white;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .header h1 {
    font-size: 3em;
    margin: 0;
  }
  
  .header p {
    font-size: 1.2em;
    margin: 0;
  }
  
  .courses {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  
  .course {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    width: 300px;
    text-align: left;
  }
  
  .course img {
    width: 100%;
    border-radius: 10px;
  }
  
  .course h2 {
    font-size: 1.5em;
    margin: 15px 0 10px;
  }
  
  .course p {
    font-size: 1em;
    color: #666;
  }
  