.custom-websites {
  font-family: 'Roboto', sans-serif;
  margin-top: 100px;
  padding: 50px;
  background: #f9f9f9;
  color: #333;
  text-align: center;
}

.cw-header {
  color: #333;
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 40px;
  position: relative;
}

.cw-header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.cw-header h1 {
  font-size: 3em;
  margin: 0;
  z-index: 1;
  padding: 0 10px;
}

.cw-header p {
  font-size: 1.2em;
  margin: 10px 0 0;
}


.divider {
  width: 0;
  height: 2px;
  background: #333;
  margin: 20px 0;
  transition: width 0.3s ease;
}

.features {
  margin-bottom: 40px;
}

.features h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.feature-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 220px;
  margin: 10px;
  text-align: center;
}

.feature .icon {
  font-size: 2.5em;
  color: rgb(247, 177, 0);
  margin-bottom: 10px;
}

.feature h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.feature p {
  font-size: 1em;
  color: #666;
}

.cta {
  margin-top: 40px;
}

.cta h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.cta p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.cta-button {
  background-color: rgb(247, 177, 0);
  color: #333;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #e59400;
}
