.custom-saas {
    font-family: 'Roboto', sans-serif;
    padding: 50px;
    background: linear-gradient(135deg, #C3DED2 0%,#e3f2fd 100%);
    color: #333;
    text-align: center;
  }
  
  .cs-header {
    background-color: #659E85;
    color: black;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
  }
  
  .cs-header h1 {
    font-size: 3em;
    margin: 0;
  }
  
  .cs-header p {
    font-size: 1.2em;
    margin: 10px 0 0;
  }
  
  .features {
    margin-bottom: 30px;
  }
  
  .features h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .feature-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .feature-description {
    flex: 1;
    padding: 20px;
    font-size: 1.2em;
    color: #333;
    text-align: left;
  }
  
  .feature-list {
    flex: 2;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .feature {
    background-color: white;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 200px;
    height: 250px;
  }
  
  .feature h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .feature p {
    font-size: 1em;
    color: #666;
  }

.feature img {
  width: 100%;
  height: 100px; /* Set a fixed height for uniformity */
  object-fit: contain; /* Ensure the whole image fits within the dimensions */
  border-radius: 10px;
}  

  .benefits {
    margin-bottom: 30px;
  }
  
  .benefits h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .benefit-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .benefit {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .benefit .icon {
    font-size: 2em;
    color: rgb(247, 177, 0);
    margin-bottom: 10px;
  }
  
  .benefit p {
    font-size: 1.2em;
    margin: 0;
    color: #333;
  }