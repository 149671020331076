.homepage {
  text-align: center;
  margin-top: 100px; /* Add margin to the top */
  padding: 50px;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  font-family: 'Roboto', sans-serif;
  position: relative;
  z-index: 0; /* Ensure main content is below the header */
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about {
  max-width: 800px;
  margin: 20px 0;
  font-size: 1.2em;
  color: #333;
}

.topics {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.topic {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  width: 300px;
  text-align: left;
  transition: transform 0.2s;
}

.topic img {
  width: 100%;
  border-radius: 10px;
}

.topic h2 {
  font-size: 1.5em;
  margin: 15px 0 10px;
}

.topic p {
  font-size: 1em;
  color: #666;
}

.topic-link {
  text-decoration: none;
  color: inherit;
}

.topic-link:hover .topic {
  transform: scale(1.05);
}
