header {
  background: #333;
  padding: 10px 0;
  position: relative;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.logo {
  display: flex;
  align-items: center;
  flex: 1;
}

.company-info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.company-name {
  color: white;
  margin: 0;
  font-size: 1.5em;
}

.slogan {
  color: #fff;
  margin: 1px 0;
  font-size: 0.75em; /* Adjust the size as needed */
}

.logo-image {
  width: 100px;
  height: 75px;
  margin-right: 10px;
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  flex: 1;
  justify-content: flex-end;
}

.nav-links li {
  margin: 0 15px;
  position: relative;
}

.nav-links li a {
  color: #fff;
  text-decoration: none;
}

/* Hamburger Menu */
.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-left: auto; /* Ensures the hamburger is aligned to the right */
}

.menu-toggle .bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 3px 0;
  transition: 0.3s;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 100%;
    left: 0;
    background: #333;
  }

  .nav-links.open {
    display: block;
  }

  .nav-links li {
    margin: px 0;
  }

  .logo-image {
    width: 80px;
    height: 60px;
  }

  .menu-toggle {
    display: flex;
    margin-right: 20px; /* Adjust the margin as needed */
  }

  .menu-toggle.open .bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 6px);
  }

  .menu-toggle.open .bar:nth-child(2) {
    opacity: 0;
  }

  .menu-toggle.open .bar:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -6px);
  }
}
