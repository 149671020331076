.contact-us {
    font-family: 'Roboto', sans-serif;
    padding: 50px;
    background: #f9f9f9;
    color: #333;
    text-align: center;
  }
  
  .contact-info {
    margin-top: 20px;
  }
  
  .contact-info h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .contact-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px; /* Space between text and email */
    flex-wrap: wrap; /* Allows content to wrap on smaller screens */
  }
  
  .contact-text {
    font-size: 1.2em;
    margin: 0;
    max-width: 600px; /* Limits width for readability */
  }
  
  .contact-details {
    background-color: rgb(247, 177, 0); /* Distinct background color */
    color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: inline-block;
  }
  
  .contact-details p {
    margin: 0;
    font-size: 1.2em;
  }
  
  .contact-details a {
    color: white; /* Ensure link color stands out */
    text-decoration: none;
  }
  
  .contact-details a:hover {
    text-decoration: underline;
  }
  